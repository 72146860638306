export default class {
    constructor({
        id,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const errors = el.querySelector('p.errors');
        const form = el.querySelector('form');

        form.onsubmit = async e => {
            e.preventDefault();

            const formData = new FormData(form);
            const response = await fetch('/', {
                method: 'POST',
                headers: { Accept: 'application/json' },
                body: formData,
            });
            const {
                // errorCode = '',
                error = '',
                success = false,
                returnUrl = '',
            } = await response.json();

            errors.textContent = '';

            if (!success) {
                errors.textContent = error;

                return;
            }

            window.location = returnUrl;
        };
    }
}
