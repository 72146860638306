import pop from 'compop';
import './polyfills';

// Components
import Accordion from './components/accordion';
import AnchorNav from './components/anchor-nav';
import ApplicationFeaturedMedia from './components/application-featured-media';
import ApplicationImageCarousel from './components/application-image-carousel';
import ApplicationInteractiveHouse from './components/application-interactive-house';
import ApplicationsDetail from './components/applications-detail';
import ApplicationsResourceDocuments from './components/applications-resource-documents';
import BackToTop from './components/back-to-top';
import BenefitsCarousel from './components/benefits-carousel';
import BlogListing from './components/blog-listing';
import BuilderProfilesCarousel from './components/builder-profiles-carousel';
import DocsModal from './components/docs-modal';
import Faq from './components/faq';
import FeaturedMedia from './components/featured-media';
import FeaturedMediaHighlight from './components/featured-media-highlight';
import FeaturedVideo from './components/featured-video';
import Form from './components/form';
import GatedButton from './components/gated-button';
import Header from './components/header';
import HeaderBanner from './components/header-banner';
import HeroBlog from './components/hero-blog';
import HeroVideo from './components/hero-video';
import Input from './components/input';
import ImageCarousel from './components/image-carousel';
import LoginForm from './components/login-form';
import Main from './components/main';
import MarketoForm from './components/marketo-form';
import MediaGate from './components/media-gate';
import Modal from './components/modal';
import ProductInteractiveHouse from './components/product-interactive-house';
import ProductImageCarousel from './components/product-image-carousel';
import ProductTable from './components/product-table';
import ProductsResourceDocuments from './components/products-resource-documents';
import RebateIframe from './components/rebate-iframe';
import RebateTiersTable from './components/rebate-tiers-table';
import VideoCarousel from './components/video-carousel';
import VideoDetail from './components/video-detail';
import VideoListing from './components/video-listing';
import VideoPlayer from './components/video-player';

const scaffold = window.rclass;

// Define map of component handles to component classes
/* eslint-disable quote-props */
const classMap = {
    'accordion': Accordion,
    'anchor-nav': AnchorNav,
    'application-featured-media': ApplicationFeaturedMedia,
    'application-image-carousel': ApplicationImageCarousel,
    'application-interactive-house': ApplicationInteractiveHouse,
    'applications-detail': ApplicationsDetail,
    'applications-resource-documents': ApplicationsResourceDocuments,
    'back-to-top': BackToTop,
    'benefits-carousel': BenefitsCarousel,
    'blog-listing': BlogListing,
    'builder-profiles-carousel': BuilderProfilesCarousel,
    'docs-modal': DocsModal,
    'faq': Faq,
    'featured-media': FeaturedMedia,
    'featured-media-highlight': FeaturedMediaHighlight,
    'featured-video': FeaturedVideo,
    'form': Form,
    'gated-button': GatedButton,
    'header': Header,
    'header-banner': HeaderBanner,
    'hero-blog': HeroBlog,
    'hero-video': HeroVideo,
    'input': Input,
    'image-carousel': ImageCarousel,
    'login-form': LoginForm,
    'main': Main,
    'media-gate': MediaGate,
    'modal': Modal,
    'marketo-form': MarketoForm,
    'product-interactive-house': ProductInteractiveHouse,
    'product-image-carousel': ProductImageCarousel,
    'product-table': ProductTable,
    'products-resource-documents': ProductsResourceDocuments,
    'rebate-tiers-table': RebateTiersTable,
    'rebate-iframe': RebateIframe,
    'video-carousel': VideoCarousel,
    'video-detail': VideoDetail,
    'video-listing': VideoListing,
    'video-player': VideoPlayer,
};
/* eslint-enable quote-props */

// Define all actions/commands that components pub/sub
const actions = {
    loadModal: 'LOAD_MODAL',
    openModal: 'OPEN_MODAL',
    closeModal: 'CLOSE_MODAL',
    lockScroll: 'LOCK_SCROLL',
    unlockScroll: 'UNLOCK_SCROLL',
    setInputValue: 'SET_INPUT_VALUE',
};

// Event handler functions
function handleAfterBlitzInject({ detail }) {
    detail.element.querySelectorAll('script')
        .forEach(script => { eval(script.textContent); }); // eslint-disable-line no-eval
}
function handleAfterBlitzInjectAll() {
    function cb() {
        const elements = document.querySelectorAll('.blitz-inject:not(.blitz-inject--injected)[data-blitz-property="input"]');

        elements.forEach(el => {
            const uri = el.getAttribute('data-blitz-uri');

            fetch(uri)
                .then(res => res.json())
                .then(({ input }) => {
                    el.innerHTML = input;
                });
        });

        const { hash } = window.location;

        if (hash) {
            const element = document.querySelector(hash);

            if (element) {
                element.scrollIntoView();
            }
        }
    }

    pop({ scaffold, classMap, actions, cb });
}

// Add event listeners
document.addEventListener('afterBlitzInject', handleAfterBlitzInject);
document.addEventListener('afterBlitzInjectAll', handleAfterBlitzInjectAll);
