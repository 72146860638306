export default class {
    constructor({
        id,
        navSecondaryHandle,
        navPrimaryHandle,
        toggleHandle,
        stickyClass,
        openClass,
        activeClass,
        fixedClass,
        revealClass,
        actions,
        events,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const navSecondary = el.querySelector(navSecondaryHandle);
        const navPrimary = el.querySelector(navPrimaryHandle);
        const navPrimaryLinks = el.querySelectorAll('nav > .header__menu > a');
        const toggle = el.querySelector(toggleHandle);
        const links = el.querySelectorAll('a');
        const gatedButtons = el.querySelectorAll('.gated-link > button');

        function getBaseUrl(url) {
            url = url.split('://')[1]; /* eslint-disable-line prefer-destructuring */
            url = url.split('#')[0]; /* eslint-disable-line prefer-destructuring */
            url = url.split('?')[0]; /* eslint-disable-line prefer-destructuring */
            url = url.replace(/\/$/, '');

            return url;
        }

        navPrimaryLinks.forEach(function(l) {
            // Check if the current URL matches the href of the navigation item exactly,
            // or if the current URL with a '#' appended matches the href of the navigation item.
            if (window.location.href === l.href || (window.location.href + '#') === l.href) {
                l.classList.toggle(activeClass);
            }

            // Check if the current URL starts with the href of the navigation item followed by a forward slash.
            // This is used to match URLs with an additional segment after the primary navigation item.
            if (window.location.href.startsWith(l.href + '/')) {
                l.classList.toggle(activeClass);
            }
        });

        let prevScrollPos = window.pageYOffset;

        function handleScrollDown(currentScrollPos) {
            navPrimary.classList.add(stickyClass);

            if (currentScrollPos < prevScrollPos) {
                el.classList.add(revealClass);
                navSecondary.classList.add(fixedClass);
            } else {
                el.classList.remove(revealClass);
                navSecondary.classList.remove(revealClass);
            }

            prevScrollPos = currentScrollPos;
        }

        function handleScrollUp() {
            el.classList.remove(revealClass);
            navPrimary.classList.remove(stickyClass);
            navSecondary.classList.remove(fixedClass);
        }

        // Event handler functions
        function handleToggle() {
            if (el.classList.contains(openClass)) {
                el.classList.remove(openClass);
                events.emit(actions.unlockScroll);
            } else {
                el.classList.add(openClass);
                events.emit(actions.lockScroll);
            }
        }
        function handleClick(e) {
            const hash = this.hash.replace('#', '');
            const target = document.getElementById(hash);

            const linkUrl = getBaseUrl(this.href);
            const currentUrl = getBaseUrl(window.location.href);

            if (el.classList.contains(openClass)) {
                el.classList.remove(openClass);
                events.emit(actions.unlockScroll);
            }

            if (target !== null && linkUrl === currentUrl) {
                e.preventDefault();

                window.scrollTo({
                    top: target.offsetTop - el.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }

            // Say if I'm on the /members page and click REQUEST A SAMPLE,
            // the active class changes from MEMBERS to REQUEST A SAMPLE
            navPrimaryLinks.forEach(function(l) {
                l.classList.toggle(activeClass, l.href == e.currentTarget.href);
            });
        }

        // Add event listeners
        window.addEventListener('scroll', () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos >= navSecondary.offsetHeight) {
                handleScrollDown(currentScrollPos);
            } else {
                handleScrollUp();
            }
        });
        gatedButtons.forEach(gb => {
            gb.addEventListener('click', handleToggle);
        });
        toggle.addEventListener('click', handleToggle);
        Array.from(links).forEach(o => {
            o.addEventListener('click', handleClick);
        });

        document.body.style.setProperty('--offset-height', `${navSecondary.offsetHeight + navPrimary.offsetHeight}px`);
    }
}
