export default class {
    constructor({
        id,
        gateId,
        redirectUrl,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const modalTrigger = el.querySelector('button');

        function handleModalTrigger() {
            fetch(`/shared/media-gate?${new URLSearchParams({
                gateId,
                redirectUrl,
            })}`)
                .then(response => response.json())
                .then(json => {
                    const { mediaGateMarkup: markup } = json;

                    events.emit(actions.loadModal, { markup, bg: 'gate' });
                })
                .catch(err => console.log(err)); // eslint-disable-line no-console
        }

        if (modalTrigger) {
            modalTrigger.addEventListener('click', handleModalTrigger);
        }
    }
}
