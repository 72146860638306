export default class {
    constructor({
        id,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const close = el.querySelector('button.close');
        const choiceView = el.querySelector('.choice-view');
        const loginButton = choiceView.querySelector('button');
        const loginView = el.querySelector('.login-view');
        const goBackButton = loginView.querySelector('button.go-back');

        function handleLoginButton() {
            choiceView.style.display = 'none';
            loginView.style.display = 'flex';
        }

        function handleGoBackButton() {
            choiceView.style.display = 'flex';
            loginView.style.display = 'none';
        }

        function handleClose() {
            events.emit(actions.closeModal);
        }

        loginButton.addEventListener('click', handleLoginButton);
        goBackButton.addEventListener('click', handleGoBackButton);
        close.addEventListener('click', handleClose);
    }
}
