import Swiper, { Navigation } from 'swiper';

export default class {
    constructor({
        id,
        gateId,
        url,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const swiperHandle = el.querySelector('.swiper');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');

        new Swiper(swiperHandle, { // eslint-disable-line no-new
            modules: [Navigation],
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            loop: true,
            spaceBetween: 20,
            slidesPerView: 1.5,
            breakpoints: {
                576: {
                    slidesPerView: 1.75,
                },
                768: {
                    slidesPerView: 2.5,
                },
                1024: {
                    slidesPerView: 2.75,
                },
                1200: {
                    slidesPerView: 3.75,
                }
            },
            on: {
                init: function () {
                    const mediaModalTriggers = el.querySelectorAll('button');

                    function handleMediaModalTrigger(e) {
                        const trigger = e.target;
                        const href = trigger.getAttribute('data-href');

                        fetch(`/shared/media-gate?${new URLSearchParams({
                            gateId,
                            redirectUrl: href,
                        })}`)
                            .then(response => response.json())
                            .then(json => {
                                const { mediaGateMarkup: markup } = json;

                                events.emit(actions.loadModal, { markup, bg: 'gate' });
                            })
                            .catch(err => console.log(err)); // eslint-disable-line no-console
                    }

                    mediaModalTriggers.forEach(trigger => {
                        trigger.addEventListener('click', handleMediaModalTrigger);
                    });
                }
            }
        });
    }
}